<template>
  <div
    v-if="visible"
    ref="quillWrapRef"
    :class="[placement]"
    class="quill-wrap snow"
    :style="getStyle"
    @click.stop="handleClick"
  >
    <div v-if="isPreview" class="rich-text-block">
      <div class="ql-editor" v-html="data"/>
    </div>
    <div v-if="editing" ref="richText" :class="[...className, isMobile ? 'wrap' : '']" class="rich-text">
      <div ref="toolbar" class="toolbar">
        <button class="ql-bold mx-1"></button>
        <button class="ql-italic mx-1"></button>
        <button class="ql-underline mx-1"></button>
        <button class="ql-strike mx-1"></button>
        <button class="ql-blockquote mx-1"></button>
        <button class="ql-fontColor mx-1">
          <font-color ref="fontColor" @change="onFontColorChange" />
        </button>
        <button v-if="$attrs?.stroke" class="ql-fontStroke">
          <font-stroke ref="fontStroke" @change="onFontStrokeChange"/>
        </button>
        <button class="ql-link mx-1"></button>
        <select v-if="!exclude.includes('font-size')" class="ql-header mx-1">
          <option value="1"></option>
          <option value="2"></option>
          <option value="3"></option>
          <option value="4"></option>
          <option value="5"></option>
          <option value="6"></option>
        </select>
        <select class="ql-font mx-1">
          <option v-for="item in fontFamily" :key="item" :value="item" />
        </select>
        <select class="ql-align mx-1">
          <option v-for="(item, index) in alignOptions" :key="index" :value="item" />
        </select>
      </div>
      <div ref="editor" v-html="data" />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {uid} from 'uid'
import 'quill/dist/quill.snow.css'
import FontStroke from './fontStroke.vue'
import FontColor from './fontColor.vue'
import {DeviceEnum} from '~/enums/deviceEnum'
// import {sleep} from "~/utils";
const fontFamily = ['sans-serif', 'BlackHanSans', 'Exo2', 'Rubik', 'RussoOne', 'Microsoft-YaHei']
export default {
  name: 'RichText',
  components: {
    FontColor,
    FontStroke
  },
  props: {
    exclude: {
      type: Array,
      default() {
        return []
      }
    },
    value: [String, Object, Number],
    visible: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'top'
    },
    noInit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: '',
      isCustomValueChange: false,
      focus: false,
      id: uid(),
      fontFamily,
      quill: null,
      alignOptions: ['', 'justify', 'center', 'right']
    }
  },

  computed: {
    ...mapState({
      device: state => state.editor?.device,
    }),
    isMobile() {
      return this.device === DeviceEnum.MOBILE
    },
    isPreview() {
      const { disabled, editing, quill } = this
      const jude = disabled || !editing
      return jude || !quill
    },
    className() {
      const result = ['editor-editing']
      if (this.disabled) {
        result.push('disabled')
      }
      if (!this.quill) result.push('opacity-0 h-0 w-0')
      const action = this.$store.state.editor.currentRichTextId === this.id ? 'focus-editor' : 'blur-editor'
      result.push(action)
      return result
    },
    getStyle() {
      return {
        '--title-stroke': this.$attrs?.stroke
      }
    }
  },
  mounted() {
    this.data = this.value ? this.value.toString() : ''
    !this.noInit && this.initEditor()
  },
  methods: {
    async initEditor() {
      const $quill = this.$refs.quillWrapRef.querySelector('.ql-container')
      if (!this.editing || $quill) return
      const Quill = (await import('quill')).default
      const fontType = Quill.import('formats/font')
      fontType.whitelist = fontFamily
      Quill.register('formats/font', fontType)
      this.quill = new Quill(this.$refs.editor, {
        theme: 'snow',
        modules: {
          toolbar: this.$refs.toolbar
        }
      })
      const format = this.quill.getFormat()
      const defaultColor = format?.color || '#000'
      this.$refs.fontColor.init(defaultColor)
      this.$refs?.fontStroke?.init(defaultColor)
      this.quill.on('text-change', () => {
        this.onChange()
      })
    },
    onFontStrokeChange(color) {
      this.$emit('update:stroke', color)
    },
    onFontColorChange(color) {
      this.quill.formatText(0, 999, { color })
    },
    handleClick() {
      if (this.editing) {
        this.$store.commit('editor/SET_CURRENT_RICH_TEXT_ID', this.id)
      }
    },
    onChange() {
      this.$emit('input', this.quill.getSemanticHTML())
    }
  }
}
</script>

<style lang="less">
.rich-size(@size, @weight: unset) {
  .rich-text-block,
  .rich-text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: @size;
      font-weight: @weight;
    }
  }
}

.editor-editing {
  .ql-container.ql-snow {
    &:hover {
      border: 1px solid @primary-color !important;
    }
  }
}

.quill-wrap {
  .rich-size(unset);
  @font-face {
    font-family: 'BlackHanSans-Regular';
    src: url(../../static/fontTypes/BlackHanSans-Regular.ttf);
    font-display: block;
  }
  @font-face {
    font-family: 'Exo2-Regular';
    src: url(../../static/fontTypes/Exo2-Regular.ttf);
    font-display: block;
  }
  @font-face {
    font-family: 'Rubik-Regular';
    src: url(../../static/fontTypes/Rubik-Regular.ttf);
    font-display: block;
  }
  @font-face {
    font-family: 'RussoOne-Regular';
    src: url(../../static/fontTypes/RussoOne-Regular.ttf);
    font-display: block;
  }
  @font-face {
    font-family: 'Sriracha-Regular';
    src: url(../../static/fontTypes/Sriracha-Regular.ttf);
    font-display: block;
  }
  .ql-tooltip {
    font-size: 12px;
    left: auto !important;
    z-index: 100;
    box-shadow: none;
  }
  .ql-font.ql-picker {
    width: auto;
    .ql-picker-label {
      padding: 0 10px;
    }
    svg {
      display: none;
    }
  }
  .font-label(@value, @family) {
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=@{value}]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=@{value}]::before {
      content: @value;
      font-family: @family !important;
    }
  }
  .ql-font-BlackHanSans {
    font-family: 'BlackHanSans-Regular',serif !important;
  }
  .ql-font-Exo2 {
    font-family: 'Exo2-Regular',serif !important;
  }
  .ql-font-Rubik {
    font-family: 'Rubik-Regular',serif !important;
  }
  .ql-font-RussoOne {
    font-family: 'RussoOne-Regular',serif !important;
  }
  .ql-font-Sriracha {
    font-family: 'Sriracha-Regular',serif !important;
  }
  .ql-font-Microsoft-YaHei {
    font-family: 'Microsoft-YaHei',serif !important;
  }
  .ql-font-sans-serif {
    font-family: sans-serif,serif !important;
  }
  .font-label('BlackHanSans', 'BlackHanSans-Regular');
  .font-label('Exo2', 'Exo2-Regular');
  .font-label('Rubik', 'Rubik-Regular');
  .font-label('RussoOne', 'RussoOne-Regular');
  .font-label('Sriracha', 'Sriracha-Regular');
  .font-label('Microsoft-YaHei', 'Microsoft-YaHei');
  .font-label('sans-serif', 'sans-serif');
  .ql-container {
    font-size: unset;
    font-family: @siteFontFamily;
  }

  .ql-fontStroke, .ql-fontType,.ql-fontColor {
    display: flex !important;
    align-items: center;
    justify-content: center;

    .iconfont {
      color: #9B95BF;
      font-size: 18px;
      font-weight: bold;

      &:hover {
        color: white;
      }
    }

    .icon-zitileixing {
      font-size: 16px;
    }
  }

  &:hover {
    .rich-text.blur-editor {
      .ql-container.ql-snow {
        border: 1px solid @primary-color !important;
      }
    }
  }
}

.ql-editor {
  padding: 0;

  p, span, h2 {
    -webkit-text-stroke: 2px var(--title-stroke) !important;
    //font-family: var(--title-family);
  }
}

.quill-wrap.bubble {
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;

  .ql-bubble .ql-picker.ql-expanded .ql-picker-options {
    background-color: @dark-bg;
  }
}

.quill-wrap {
  &.top {
    .ql-toolbar.ql-snow {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -105%);
    }
  }

  &.bottom {
    .ql-toolbar.ql-snow {
      position: absolute;
      top: 105%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &.left-top {
    .ql-toolbar.ql-snow {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0, -105%);
    }
  }

  &.right-top {
    .ql-toolbar.ql-snow {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(0, -105%);
    }
  }
}

.rich-text {
  position: relative;
  align-items: center;
  .toolbar {
    //
  }
  .ql-picker.ql-header {

    .ql-picker-label::before,
    .ql-picker-item::before {
      content: '文本';
    }

    .ql-picker-label[data-value='1']::before,
    .ql-picker-item[data-value='1']::before {
      content: '标题';
    }

    .ql-picker-label[data-value='2']::before,
    .ql-picker-item[data-value='2']::before {
      content: '描述';
    }

    .ql-picker-label[data-value='3']::before,
    .ql-picker-item[data-value='3']::before {
      content: '标题3';
    }

    .ql-picker-label[data-value='4']::before,
    .ql-picker-item[data-value='4']::before {
      content: '标题4';
    }

    .ql-picker-label[data-value='5']::before,
    .ql-picker-item[data-value='5']::before {
      content: '标题5';
    }

    .ql-picker-label[data-value='6']::before,
    .ql-picker-item[data-value='6']::before {
      content: '标题6';
    }
  }

  &.focus-editor {
    .ql-toolbar.ql-snow {
      display: flex;
      flex-wrap: nowrap;
    }

    &.wrap {
      .ql-toolbar.ql-snow {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .ql-toolbar.ql-snow .ql-formats {
      display: flex;
      flex-wrap: nowrap;
    }

    .ql-container.ql-snow {
      //background-color: #1a1837;
      border: 1px solid @primary-color !important;
    }
  }

  &.blur-editor {
    .ql-toolbar.ql-snow {
      display: none;
    }

    .ql-container.ql-snow {
      border: 1px solid transparent;
    }
  }

  .ql-toolbar.ql-snow {
    display: none;
    padding: 5px;
    letter-spacing: normal;
    white-space: nowrap;
    border: 0;
    background-color: @primary-3;
    width: fit-content;
    border-radius: 4px;
    line-height: 20px;
    z-index: 100;

    .ql-header {
      background-color: @fill-color-2;
    }

    button,
    .ql-picker-label {
      &:hover {
        .ql-stroke {
          stroke: white;
        }

        .ql-fill {
          fill: white;
        }
      }
    }

    button.ql-active {
      .ql-stroke {
        stroke: @primary-color;
      }

      .ql-fill {
        fill: @primary-color;
      }
    }

    .ql-stroke {
      stroke: @text-3-hex;
    }

    .ql-picker-options {
      .ql-stroke {
        stroke: #444;
      }
    }
  }

  .ql-snow .ql-picker-label {
    color: @text-3-hex;

    &.ql-active {
      .ql-stroke {
        color: @primary-color;
        stroke: @primary-color;
      }
    }
  }

  .ql-align,
  .ql-background,
  .ql-color {
    .ql-picker-label {
      padding-top: 0;
      color: white;
    }
  }

  .ql-snow .ql-picker.ql-expanded {
    .ql-picker-label {
      color: @text-3-hex;
    }

    .ql-picker-options {
    @apply z-50;
    }
  }

  .ql-container.ql-snow {
    border: 1px solid transparent;
  }
}

.editor-toolbar-empty {
  &.focus-editor {
    .ql-toolbar.ql-snow {
    @apply hidden p-0 m-0;
    }
  }
}
</style>
